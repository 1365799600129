/* eslint-disable complexity */
import {
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	type PaginationProps,
	Row,
	Select,
	Space,
	Spin,
	Tabs,
	message,
} from 'antd';
import dayjs from 'dayjs';
import { compact, isNil } from 'lodash-es';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { TrpcBase } from '../../../utils/trpc';
import Attachment from './Attachment';
import { CheckInItem } from './CheckInItem';
import { DisplayLastRead } from './DisplayLastRead';
import { LeaseItem } from './LeaseItem';
import { MonthlyStatementItem } from './MonthlyStatementItem';
import { OpenTheToken } from './OpenTheToken';
import QuickSetTimeAtCheckIn from './QuickSetTimeAtCheckIn';
import QuickSetTimeAtMonthlyStatement from './QuickSetTimeAtMonthlyStatement';
import {
	categorySelectOption,
	constantCheckIn,
	constantLease,
	constantMonthlyStatement,
} from './categorySelectOption';
import { checkInTimeShouldBeforeCheckOutTime } from './checkInTimeShouldBeforeCheckOutTime';
import { commitConvert } from './commitConvert';
import { validDateInCheckIn } from './validDateInCheckIn';
import { verifyRoomNumber } from './verifyRoomNumber';
// import request from './request';
import JsonView from '@uiw/react-json-view';
import { Pagination } from 'antd';
import classNames from 'classnames';
import { useBuildingConfig } from '../useBuildingConfig';
import { UsedTrendView } from './UsedTrendView';

// @ts-expect-error diff not have type
import diff from '../../../lib/diff/diff';
import { useApartmentList } from '../overview/useApartmentList';
import useGetCurrentFeeConfig from './useGetCurrentFeeConfig';
import { tailLayout } from './tailLayout';

const { TextArea } = Input;
const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};
const enterURL = '/apartment/entering';

export default function Edit() {
	const [form] = Form.useForm();
	const { currentBuildingConfig, currentSelectedBuildingId } =
		useBuildingConfig();
	const { data: configure } = TrpcBase.apartment.getRooms.useQuery();

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const uuid = searchParams.get('uuid');
	const categoryParams = searchParams.get('category');
	const roomNumberParams = searchParams.get('roomNumber');
	const tabParams = searchParams.get('tab') ?? '1';
	const isEditMode = location.pathname !== enterURL;

	const { data: apartmentDetailStruct, isFetched: isLoadedDetail } =
		TrpcBase.apartment.details.useQuery(
			{
				uuids: uuid ? [uuid] : [],
			},
			{
				enabled: uuid !== undefined && uuid !== null,
				networkMode: 'online',
				staleTime: 0,
				cacheTime: 0,
			},
		);

	const roomNumber =
		Form.useWatch('roomNumber', form) ??
		roomNumberParams ??
		apartmentDetailStruct?.detail.at(0)?.roomNumber;

	const {
		valuation,
		isLoading: isFeeLoading,
		isError,
	} = useGetCurrentFeeConfig({
		roomNumber: roomNumber,
	});

	const [current, setCurrent] = useState(1);

	const onChange: PaginationProps['onChange'] = (page) => {
		setCurrent(page);
	};

	const action = TrpcBase.apartment.save.useMutation();

	const apartmentDetail = apartmentDetailStruct?.detail.at(0);

	const roomConfigure = configure?.find((i) => i.roomNumber === roomNumber);

	const formCategory = Form.useWatch('category', form);
	const formCheckInTime =
		formCategory === constantLease
			? Form.useWatch('checkOutTime', form)
			: Form.useWatch('checkInTime', form);
	const formCheckOutTime = Form.useWatch('checkOutTime', form);

	const { list: roomHistoryData, refetch: fetchRoomHistory } = useApartmentList(
		{
			roomNumber: !isNil(roomNumber) && roomNumber,
			// startTime:
			// 	formCheckInTime &&
			// 	startOfMonth(formCheckInTime.subtract(1, 'years')).valueOf(),
			endTime: formCheckOutTime && endOfMonth(formCheckInTime).valueOf(),
			limit: 5,
			orderBy: 'checkInTime',
		},
		!isNil(formCheckInTime) && !isNil(formCheckOutTime),
	);

	// 排除了本次申请单，的其他申请单。
	const roomHistory = roomHistoryData
		?.filter((i) => {
			return i.uuid !== uuid;
		})
		?.sort((a, b) => {
			return dayjs(b.checkInTime).valueOf() - dayjs(a.checkInTime).valueOf();
		});

	const roomLastInfoList = roomHistory;

	const roomLastInfoListLength = roomLastInfoList?.length ?? 0;

	let initFormData = apartmentDetail;

	if (initFormData?.checkInTime && initFormData.checkOutTime) {
		initFormData = {
			...initFormData,
			time: [dayjs(initFormData.checkInTime), dayjs(initFormData.checkOutTime)],
			checkInTime: dayjs(initFormData.checkInTime),
			checkOutTime: dayjs(initFormData.checkOutTime),
		};
	}

	if (location.pathname === enterURL) {
		initFormData = {
			category: categoryParams,
			roomNumber: roomNumberParams,
		};

		// 如果是月结单进入 默认填写下个月的时间?
	}

	// eslint-disable-next-line arrow-body-style
	// const disabledDate: RangePickerProps['disabledDate'] = (current) => {
	//   // Can not select days before today and today
	//   const prev = roomHistoryData?.filter((b) => b.uuid !== uuid) ?? [];

	//   return prev.every((i) => {
	//     return dayjs(i).isBetween(i.checkInTime, i.checkOutTime, 'day', '[]') === false;
	//   });
	// };

	const onFinish = async (values) => {
		const formData = commitConvert(values);

		try {
			const res = await action.mutateAsync(formData);

			message.info(location.pathname === enterURL ? '保存成功' : '更新成功');

			navigate('/apartment/overview');
		} catch (error) {
			message.error(error.message);
		}
	};

	if (isFeeLoading || (isEditMode && isLoadedDetail === false)) {
		return <div>fetching</div>;
	}

	if (isError) {
		return (
			<Button
				onClick={() => {
					console.log(currentBuildingConfig);
					navigate(`/apartment/roomConfigure/${currentSelectedBuildingId}`);
				}}
			>
				请先配置房间关联价格
			</Button>
		);
	}

	const editForm = (
		<>
			{isEditMode && (
				<Form.Item label="uuid" name="uuid" rules={[{ required: isEditMode }]}>
					<Input disabled={isEditMode} />
				</Form.Item>
			)}

			<Form.Item label="类别" name="category" rules={[{ required: true }]}>
				<Select
					disabled={!location.hostname.includes('localhost')}
					options={categorySelectOption}
				/>
			</Form.Item>

			{(formCategory === constantCheckIn ||
				formCategory === constantMonthlyStatement) && (
				<Row>
					<Col span={12}>
						<Form.Item
							name="checkInTime"
							label={formCategory === constantCheckIn ? '入住时间' : '起算时间'}
							rules={[{ required: true }]}
						>
							<DatePicker />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="结算时间"
							name="checkOutTime"
							dependencies={['checkInTime']}
							validateFirst={true}
							rules={[
								{ required: true, message: '请输入结算时间' },
								({ getFieldValue }) => {
									return {
										validator: (_, checkOutTime) => {
											const checkInTime = getFieldValue('checkInTime');

											return checkInTimeShouldBeforeCheckOutTime(
												checkInTime,
												checkOutTime,
											);
										},
									};
								},
								({ getFieldValue }) => {
									return {
										warningOnly: true,
										validator: async (_, checkOutTime) => {
											const checkInTime = getFieldValue('checkInTime');
											const prev =
												(await fetchRoomHistory()).data?.list?.filter(
													(b) => b.uuid !== uuid,
												) ?? [];

											const invalidItem = prev?.find((i) => {
												return (
													validDateInCheckIn(
														[checkInTime, checkOutTime],
														[dayjs(i?.checkInTime), dayjs(i?.checkOutTime)],
														i.uuid,
													).error === true
												);
											});

											if (invalidItem) {
												const errorInfo = validDateInCheckIn(
													[checkInTime, checkOutTime],
													[
														dayjs(invalidItem?.checkInTime),
														dayjs(invalidItem?.checkOutTime),
													],
													invalidItem.uuid,
												);

												return Promise.reject(errorInfo?.message);
											}

											return Promise.resolve();
										},
									};
								},
							]}
						>
							<DatePicker />
						</Form.Item>
					</Col>
				</Row>
			)}

			<QuickSetTimeAtMonthlyStatement />
			<QuickSetTimeAtCheckIn />

			{formCategory === constantLease && (
				<Form.Item
					label="退租时间"
					name="checkOutTime"
					dependencies={['checkInTime']}
					validateFirst={true}
					rules={[
						{ required: true, message: '请输入退租时间' },
						({ getFieldValue }) => {
							return {
								warningOnly: true,
								validator: async (_, checkOutTime) => {
									const prev =
										(await fetchRoomHistory())?.data?.list?.filter(
											(b) => b.uuid !== uuid,
										) ?? [];

									const shouldItem = prev?.find((i) => {
										return dayjs(checkOutTime).isBetween(
											i.checkInTime,
											i.checkOutTime,
											'day',
											'[]',
										);
									});

									if (!shouldItem) {
										return Promise.reject(
											'退租时间不在租客续租时间内，仅提示，注意补填房租',
										);
									}

									return Promise.resolve();
								},
							};
						},
					]}
				>
					<DatePicker />
				</Form.Item>
			)}

			<Row>
				<Col span={12}>
					<Form.Item
						label="房间号"
						name="roomNumber"
						rules={[
							{ required: true, message: '请输入房间号' },
							{ validator: verifyRoomNumber },
						]}
						// shouldUpdate
					>
						{/* {(form) => {
              // console.log(`form.getFieldValue('roomNumber')`, form.getFieldValue('roomNumber'));
              return <div>{form.getFieldValue('roomNumber')}</div>;
            }} */}
						{/* {roomNumber} */}
						<Input disabled={true} placeholder="房间号" />
					</Form.Item>

					{/* <Button
          onClick={() => {
            refetch();
          }}
        >
          显示上期申请单读数
        </Button> */}
				</Col>

				<Col span={12}>
					<Form.Item
						label="房租"
						name="rent"
						rules={compact([
							{
								required:
									formCategory === constantCheckIn ||
									formCategory === constantMonthlyStatement,
								message: '请输入房租',
							},
							currentBuildingConfig?.buildingId === 'oluh' &&
								roomConfigure?.rentAllocation && {
									type: 'number',
									len: roomConfigure?.rentAllocation,
									warningOnly: true,
									message: `提醒，房租值一般为${roomConfigure?.rentAllocation}`,
								},
						])}
					>
						<InputNumber placeholder="房租" />
					</Form.Item>
				</Col>
			</Row>
			{formCategory === constantMonthlyStatement && <MonthlyStatementItem />}
			{formCategory === constantCheckIn && <CheckInItem />}
			{formCategory === constantLease && <LeaseItem />}

			<Form.Item label="备注" name="remark" rules={[]}>
				<TextArea placeholder="备注" autoSize={{ minRows: 3, maxRows: 5 }} />
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit">
					录入
				</Button>
			</Form.Item>

			<Form.Item shouldUpdate>
				{({ getFieldsError }) => {
					const errors = getFieldsError().filter((i) => i.errors.length > 0);

					return <div>{errors.map((i) => i.errors).join('\n')}</div>;
				}}
			</Form.Item>
		</>
	);

	return (
		<div className={'bg-white px-5 py-2'}>
			<Tabs
				defaultActiveKey={tabParams}
				items={[
					{
						key: '1',
						label: '单据详情',
						children: (
							<Spin
								spinning={
									action.isLoading || (isEditMode && isLoadedDetail === false)
								}
							>
								<Form
									// {...layout}
									form={form}
									// layout={'vertical'}
									className={''}
									initialValues={initFormData}
									onFinish={onFinish}
									onFinishFailed={(...err) => {
										console.log(...err);
									}}
									onValuesChange={(v, all) => {
										if (
											all.category === constantLease &&
											!isNil(all.checkInTime)
										) {
											all.checkInTime = all.checkOutTime;
										}
									}}
								>
									<div className="grid md:grid-cols-3 xl:grid-cols-3 gap-2 ">
										{/* {roomLastInfoListLength > 0 && ( */}
										<div className="flex flex-col overflow-x-auto">
											<Pagination
												current={current}
												onChange={onChange}
												showQuickJumper={false}
												showSizeChanger={false}
												total={roomLastInfoListLength * 10}
											/>
											<DisplayLastRead
												lastInfo={roomLastInfoList?.[current - 1]}
												onClick={undefined}
											/>
										</div>
										{/* )} */}

										<div className="grid col-span-2">
											{editForm}

											{(formCategory === constantCheckIn ||
												formCategory === constantMonthlyStatement) && (
												<OpenTheToken
													roomHistory={roomHistory}
													time={[formCheckInTime, formCheckOutTime]}
												/>
											)}
										</div>
									</div>
								</Form>
							</Spin>
						),
					},
					{
						key: '2',
						label: '附件查看',
						children: <Attachment />,
					},

					{
						key: 'log',
						label: '日志信息',
						children: apartmentDetailStruct?.log.data
							.toReversed()
							.map((i, index, ori) => {
								const change =
									index - 1 < 0
										? i.operateContent
										: diff(ori[index - 1]?.operateContent, i.operateContent);

								return (
									<div className="my-3 grid grid-cols-2">
										<JsonView value={change} />
										<JsonView value={i} />
									</div>
								);
							}),
					},

					{
						key: '4',
						label: '趋势',
						children: <UsedTrendView roomLastInfoList={roomLastInfoList} />,
					},
					{
						key: '5',
						label: '调试',
						children: (
							<Card>
								<div>配置:</div>
								<JsonView value={valuation} />
							</Card>
						),
					},
				]}
			/>
		</div>
	);
}

import type { Dayjs } from 'dayjs';
import dayjs, { extend } from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
extend(isSameOrBefore);
extend(isSameOrAfter);
extend(isBetween);

// eslint-disable-next-line complexity
export function validDateInCheckIn(
	selectedDays: [Dayjs, Dayjs],
	effectedDays: [Dayjs, Dayjs],
	uuid: string,
) {
	const [selectedDay1, selectedDay2] = selectedDays;
	const [effectedDay1, effectedDay2] = effectedDays;

	const errorMessage = `选择时间已经与 ${uuid}单据 时间重叠，即应在 ${effectedDay1.format(
		'YYYY-MM-DD',
	)} 至 ${effectedDay2.format('YYYY-MM-DD')} 以外`;
	const errorObject = {
		error: true,
		message: errorMessage,
	};

	if (
		dayjs(selectedDay1).isBetween(effectedDay1, effectedDay2, 'day', '[]') &&
		dayjs(selectedDay2).isBetween(effectedDay1, effectedDay2, 'day', '[]')
	) {
		return errorObject;
	}

	if (
		dayjs(selectedDay1).isSameOrBefore(effectedDay1, 'day') &&
		dayjs(selectedDay2).isBetween(effectedDay1, effectedDay2, 'day', '[]')
	) {
		return errorObject;
	}

	if (
		dayjs(selectedDay2).isSameOrAfter(effectedDay2, 'day') &&
		dayjs(selectedDay1).isBetween(effectedDay1, effectedDay2, 'day', '[]')
	) {
		return errorObject;
	}

	if (
		dayjs(effectedDay1).isBetween(selectedDay1, selectedDay2, 'day', '[]') &&
		dayjs(effectedDay2).isBetween(selectedDay1, selectedDay2, 'day', '[]')
	) {
		return errorObject;
	}

	return { error: false };
}

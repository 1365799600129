/* eslint-disable complexity */
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { Button, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { constantMonthlyStatement } from './categorySelectOption';
import { formatDateMode } from './formatDateMode';

export default function QuickSetTimeAtMonthlyStatement() {
	const form = Form.useFormInstance();
	const formCategory = Form.useWatch('category', form);

	return formCategory === constantMonthlyStatement ? (
		<Form.Item label="快速设置时间">
			<div className="flex flex-col gap-2 items-start">
				{[
					{
						checkInTime: startOfMonth(dayjs().startOf('hour')),
						checkInTimeLabel: '本月初',
						checkOutTime: endOfMonth(dayjs()),
						checkOutTimeLabel: '本月末',
					},

					{
						checkInTime: startOfMonth(dayjs().add(1, 'M').startOf('hour')),
						checkInTimeLabel: '下月初',
						checkOutTime: endOfMonth(dayjs().add(1, 'M')),
						checkOutTimeLabel: '下月末',
					},
				].map((i) => {
					const action = () => {
						form.setFieldsValue({
							checkInTime: i.checkInTime,
							checkOutTime: i.checkOutTime,
						});

						form.validateFields(['checkInTime', 'checkOutTime']);
					};

					return (
						<div
							key={i.checkInTimeLabel + i.checkOutTimeLabel}
							className="px-2 py-1 border-solid border border-slate-300 rounded cursor-pointer "
							onClick={action}
							onKeyDown={action}
						>
							<div className="flex flex-row gap-2">
								从 <div className="text-red-700">{i.checkInTimeLabel}</div>
								{i.checkInTime.startOf('hour').format(formatDateMode)} 到
								<div className="text-red-700">{i.checkOutTimeLabel}</div>
								{i.checkOutTime.format(formatDateMode)}
							</div>
						</div>
					);
				})}
			</div>
		</Form.Item>
	) : null;
}

import type { Dayjs } from 'dayjs';
import { extend } from 'dayjs';
import { isNil } from 'lodash-es';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
extend(isSameOrBefore);

export async function checkInTimeShouldBeforeCheckOutTime(
	checkInTime?: Dayjs,
	checkOutTime?: Dayjs,
) {
	if (isNil(checkInTime) || isNil(checkOutTime)) {
		return Promise.resolve();
	}

	if (checkOutTime.month() !== checkInTime.month()) {
		return Promise.reject('入住时间与退租时间的月份应该一致');
	}

	if (checkOutTime.isAfter(checkInTime.add(1, 'month'))) {
		return Promise.reject('入住时间与退租时间的时差应在一个月内');
	}

	if (checkInTime.isAfter(checkOutTime)) {
		return Promise.reject('入住时间应早于退租时间');
	}

	return Promise.resolve();
}

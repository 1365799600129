import {
	constantCheckIn,
	constantLease,
	constantMonthlyStatement,
} from './categorySelectOption';

/** 提交前的转换 */
export function commitConvert(v: any) {
	const vDone = v;

	if (vDone?.time) {
		vDone.checkInTime = vDone?.time?.[0]?.valueOf();
		vDone.checkOutTime = vDone?.time?.[1]?.valueOf();
		delete vDone.time;
	}

	if (vDone?.checkInTime && vDone.checkInTime?.valueOf) {
		vDone.checkInTime = vDone?.checkInTime?.valueOf();
	}

	if (vDone?.checkOutTime && vDone.checkOutTime?.valueOf) {
		vDone.checkOutTime = vDone?.checkOutTime?.valueOf();
	}

	// 入住单 水电应该保持一致
	if (vDone.category === constantCheckIn) {
		vDone.electricMeterReadingThisMonth = vDone.electricMeterReadingLastMonth;
		vDone.hotWaterMeterReadingThisMonth = vDone.hotWaterMeterReadingLastMonth;

		vDone.coldWaterMeterReadingThisMonth = vDone.coldWaterMeterReadingLastMonth;
	}

	// 月结单 押金为 0
	if (vDone.category === constantMonthlyStatement) {
		vDone.deposit = 0;
	}

	// 退租单 没有房租 入住时间和退租时间一致
	if (vDone.category === constantLease) {
		// vDone.rent = 0;
		// 有些是灵活退房 导致要补足房租

		vDone.checkInTime = vDone?.checkOutTime;
	}

	return vDone;
}

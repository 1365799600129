import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import { validDateInCheckIn } from './validDateInCheckIn';

export function OpenTheToken({ roomHistory, time }: any) {
	const invalidItem = roomHistory?.find((i) => {
		return (
			validDateInCheckIn(
				[time?.[0], time?.[1]],
				[dayjs(i?.checkInTime), dayjs(i?.checkOutTime)],
				i.uuid,
			).error === true
		);
	});

	if (!invalidItem) {
		return null;
	}

	return (
		<Form.Item label="时间重叠的申请单">
			<Button
				className="text-red"
				onClick={() => {
					window.open(`/apartment/edit?uuid=${invalidItem?.uuid}`);
				}}
			>
				打开
			</Button>
		</Form.Item>
	);
}

/* eslint-disable complexity */
import { Button, Card, Descriptions, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { apartmentDetailColumns, dateFormatShow } from '../constants';
import { getOptionValueOrLabel } from './getOptionValueOrLabel';
import { useApartmentList } from '../overview/useApartmentList';

export function DisplayLastRead({
	lastInfo,
	onClick,
}: { lastInfo: ReturnType<typeof useApartmentList>['list'][0]; onClick: any }) {
	const form = Form.useFormInstance();
	return (
		lastInfo && (
			<>
				<Descriptions
					title={`${lastInfo.roomNumber}房 上次单据详情`}
					column={1}
				>
					<Descriptions.Item label="类型">
						{lastInfo.category && getOptionValueOrLabel(lastInfo.category)}
					</Descriptions.Item>

					<Descriptions.Item label="入住时间-退租时间">{`${dayjs(
						lastInfo.checkInTime,
					).format(dateFormatShow)}至${dayjs(lastInfo.checkOutTime).format(
						dateFormatShow,
					)}`}</Descriptions.Item>

					<Descriptions.Item label="房间号">
						{lastInfo.roomNumber}
					</Descriptions.Item>
					<Descriptions.Item label="房租">
						{lastInfo.rent.toString()}
					</Descriptions.Item>

					{apartmentDetailColumns.map((p) => (
						<Descriptions.Item key={p.key} label={p.title}>
							{lastInfo[p.key].toString()}
						</Descriptions.Item>
					))}

					<Descriptions.Item label="备注">{lastInfo.remark}</Descriptions.Item>
				</Descriptions>

				<Space direction="vertical">
					<Button
						className="my-2"
						onClick={() => {
							lastInfo &&
								form.setFieldsValue({
									electricMeterReadingLastMonth:
										lastInfo['electricMeterReadingThisMonth'],
									hotWaterMeterReadingLastMonth:
										lastInfo['hotWaterMeterReadingThisMonth'],
									coldWaterMeterReadingLastMonth:
										lastInfo['coldWaterMeterReadingThisMonth'],
								});
						}}
					>
						电表、冷水表、热水表用表读数迁移
					</Button>

					<Button
						className="my-2"
						onClick={() => {
							lastInfo &&
								form.setFieldsValue({
									rent: lastInfo['rent'],
								});
						}}
					>
						房租迁移
					</Button>

					<Button
						className="mt-10"
						onClick={() => {
							lastInfo &&
								form.setFieldsValue({
									electricMeterReadingLastMonth:
										lastInfo['electricMeterReadingThisMonth'],
									hotWaterMeterReadingLastMonth:
										lastInfo['hotWaterMeterReadingThisMonth'],
									coldWaterMeterReadingLastMonth:
										lastInfo['coldWaterMeterReadingThisMonth'],
									rent: lastInfo['rent'],
								});
						}}
					>
						房租、电表、冷水表、热水表用表读数迁移
					</Button>
				</Space>
			</>
		)
	);
}

import { Card, Image, Space } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './edit.module.css';
import useAttachmentHook from './useAttachmentHook';

export default function Attachment() {
	const [searchParams, setSearchParams] = useSearchParams();
	const uuid = searchParams.get('uuid');
	if (!uuid) {
		throw new Error('no uuid');
	}

	const { getAttachmentUrl, Dom, uploadFileAction } = useAttachmentHook(
		uuid,
		true,
	);

	useEffect(() => {
		const domHandler = document.addEventListener('paste', async (e) => {
			const items = e?.clipboardData?.items;
			if (!items) {
				return;
			}

			for (const item of items) {
				const dd = await item.getAsFileSystemHandle();
				const currentFile = await dd.getFile();

				console.log(currentFile);

				if (currentFile.type.includes('image')) {
					await uploadFileAction(currentFile);
				}
			}
		});

		return () => {
			document.removeEventListener('paste', domHandler);
		};
	}, [uploadFileAction]);

	return (
		<Card>
			<div className={styles.layout}>
				<Space direction="vertical">
					{getAttachmentUrl?.data && <Image src={getAttachmentUrl?.data} />}
					{/*
                          如果没有 beforeUpload 的设置，文件上传会触发好几次
                          https://ant.design/components/upload#why-does-onchange-sometimes-return-file-object-and-other-times-return--originfileobj-file-
                      */}

					{Dom}
				</Space>
			</div>
		</Card>
	);
}
